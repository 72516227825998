import parse, { attributesToProps, DOMNode, Element } from "html-react-parser"
import React from "react"
import YoutubePlayer from "../components/YouTubePlayer/YoutubePlayer"

const domNodeIsElement = (domNode: DOMNode): domNode is Element => {
  return domNode.type === "tag"
}

export const parseHTML = (html) => {
  return parse(html, {
    replace: (domNode) => {
      if (
        domNodeIsElement(domNode) &&
        domNode.name === "iframe" &&
        domNode.attribs &&
        domNode.attribs.src &&
        domNode.attribs.src.includes("youtube")
      ) {
        const props = attributesToProps(domNode.attribs)

        return (
          <>
            <YoutubePlayer
              url={domNode.attribs.src}
              height={domNode.attribs.height + "px"}
              width={domNode.attribs.width + "px"}
              style={{
                display: "inline-block",
                maxHeight: "100%",
                maxWidth: "100%",
                ...props.style,
              }}
              controls
            />
            {/* for some reason it doesn't work without this iframe(??) */}
            <iframe style={{ display: "none" }}></iframe>
          </>
        )
      }
    },
    trim: true,
  })
}
