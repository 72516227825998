import React from 'react'
import { getLanguageByLocation } from './helpers'

export const useLocale = () => {
  const [locale, setLocale] = React.useState('en')

  React.useEffect(() => {
    setLocale(getLanguageByLocation(location));
  }, [])

  return locale
}
