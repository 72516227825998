import React, { useEffect } from "react"

const cookiesList = {
  necessary: true,
  preferences: false,
  analytics: false,
  marketing: false,
  uncategorized: false,
} as const

// https://support.cookiehub.com/article/86-methods-functions
declare global {
  interface Window {
    cookiehub:
      | {
          hasConsented: (
            cookieName: keyof typeof cookiesList | string
          ) => boolean
        }
      | undefined
  }
}

/**
 * @param ref - Ref to element with data-consent attribute (e.g. \<div data-consent="marketing"/>)
 * @returns
 */
export const useCookiehub = (
  ref: React.MutableRefObject<HTMLElement | null>
) => {
  const [preferences, setPreferences] = React.useState({ ...cookiesList })

  const updateCookies = () => {
    Object.keys(cookiesList).forEach((cookieName) => {
      setPreferences((prev) => ({
        ...prev,
        [cookieName]: window.cookiehub?.hasConsented(cookieName),
      }))
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateCookies()
    }, 0)

    // https://stackoverflow.com/a/41425087
    // It's just observing changes for attributes on element with data-consent attribute
    // When consent changes cookiehub updates style of those elements, and we are catching it here
    // It's the easiest way to get consent changes that I found
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          updateCookies()
        }
      })
    })

    observer.observe(ref.current, {
      attributes: true,
    })

    return () => {
      observer.disconnect()
      clearTimeout(timeout)
    }
  }, [])

  return preferences
}
